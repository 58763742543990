import {ThemeOptions} from "@mui/material";

const threeTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#2EC4B6',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#CBF3F0',
        },
        warning: {
            main: '#FFBF69',
        },
        error: {
            main: '#FF9F1C',
        },
        common: {
            white: '#FFF',
        }
    }
};

export default threeTheme;
