import {ThemeOptions} from "@mui/material";

const defaultTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#577590',
        },
        secondary: {
            main: '#F3CA40',
        },
        warning: {
            main: '#F2A541',
        },
        error: {
            main: '#F08A4B',
        },
    }
};

export default defaultTheme;
