import defaultTheme from "./default";
import oneTheme from "./one";
import twoTheme from "./two";
import threeTheme from "./three";
import fourTheme from "./four";

export default {
    default: defaultTheme,
    one: oneTheme,
    two: twoTheme,
    three: threeTheme,
    four: fourTheme,
};
